import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteInscription,
  getTournament,
  getTournamentsByName,
  postInscription,
  createTournament,
  acceptUser,
  updateTournament,
  getUserCreatedTournaments,
  getUserInscribedTournaments,
} from "../api/api";

const key = "TOURNAMENTS";

export function useTournaments(name: string, options: object = {}) {
  return useQuery([key, "search", name], () => getTournamentsByName(name), {
    ...options,
  });
}

export function useTournament(tournamentId) {
  return useQuery([key, "id", tournamentId], () => getTournament(tournamentId));
}

export function useCreatedTournaments(userId) {
  return useQuery([key, "created", userId], () =>
    getUserCreatedTournaments(userId)
  );
}

export function useInscribedTournaments(userId) {
  return useQuery([key, "inscribed", userId], () =>
    getUserInscribedTournaments(userId)
  );
}

export function useInscriptionMutation() {
  const queryClient = useQueryClient();
  return useMutation((tournamentId: string) => postInscription(tournamentId), {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([key, "id", variables]);
    },
  });
}

export function useUpdateInscriptionStatusMutation() {
  const queryClient = useQueryClient();
  return useMutation<
    void,
    unknown,
    { tournamentId: string; userId: string },
    () => void
  >(({ tournamentId, userId }) => acceptUser(tournamentId, userId), {
    onSuccess: (data, { tournamentId }) => {
      queryClient.invalidateQueries([key, "id", tournamentId]);
    },
  });
}

export function useDeinscriptionMutation() {
  const queryClient = useQueryClient();
  return useMutation<
    void,
    unknown,
    { tournamentId: string; userId: string },
    () => void
  >(({ tournamentId, userId }) => deleteInscription(tournamentId, userId), {
    onSuccess: (data, { tournamentId }) => {
      queryClient.invalidateQueries([key, "id", tournamentId]);
    },
  });
}

export function useCreateTournament() {
  return useMutation((tournament: any) => createTournament(tournament));
}

export function useUpdateTournament() {
  interface tournamentToUpdate {
    tournament: object;
    tournamentId: string;
  }
  const queryClient = useQueryClient();
  return useMutation<
    void,
    unknown,
    { tournament: object; tournamentId: string },
    () => void
  >(
    ({ tournament, tournamentId }) =>
      updateTournament(tournament, tournamentId),
    {
      onSuccess: (data, { tournamentId }) => {
        queryClient.invalidateQueries([key, "id", tournamentId]);
      },
    }
  );
}
