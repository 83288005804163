import "./App.less";
import { Auth } from "./components/Providers/Auth";
import { Main } from "./views";

function App() {
  return (
    <div className="App">
      <Auth>
        <Main />
      </Auth>
    </div>
  );
}

export default App;
