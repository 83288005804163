import { Badge, Button, Divider, Dropdown, Image, Layout, Menu, Modal, Space, Typography } from "antd";
import type { MenuProps } from "antd";
import { useContext, useState } from "react";
import { AuthContext } from "./Providers/Auth";
import { DownOutlined } from "@ant-design/icons";
import { useUserPoints } from "../hooks/users";
import Instructions from "./Instructions";
const { Header: AntHeader } = Layout;

export function Header() {
  const { authed, logout, userSession } = useContext(AuthContext);

  const [isInstructionsVisible, setIsInstructionsVisible] = useState(false);

  const { data: points, isLoading, error } = useUserPoints(userSession?.user.username, {
    enabled: !!userSession?.user.username
  });

  const items: MenuProps["items"] = [
    {
      key: "dashboard",
      label: <a href="/dashboard">Cambiar contraseña</a>,
    },
    {
      type: "divider",
    },
    {
      key: "logout",
      label: <a onClick={logout}>Cerrar Sesión</a>,
    },
  ];

  const userMenuProps = {
    items,
  };

  const unauthedItems = [
    {
      key: "login",
      label: <a href="/login">Ingresar</a>,
    },
    {
      key: "signup",
      label: <a href="/signup">Registrarse</a>,
    },
    {
      key: "rules",
      label: <a href="#" onClick={(e) => {e.preventDefault(); setIsInstructionsVisible(true); }}>Reglamento</a>,
    },
  ];

  const authedItems = [
    {
      key: "predictions",
      label: <a href="/">Pronósticos</a>,
    },
    {
      key: "tournaments",
      label: <a href="/tournaments">Torneos</a>,
    },
    {
      key: "rules",
      label: <a href="#" onClick={(e) => {e.preventDefault(); setIsInstructionsVisible(true); }}>Reglamento</a>,
    },
    {
      key: "user",
      label: (
        <Dropdown overlay={<Menu {...userMenuProps}></Menu>}>
          <Button>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {userSession?.preferred_username}
              {points !== undefined && <Badge count={`${points} puntos`} showZero style={{ backgroundColor: '#656565', margin: '0 .5rem' }}></Badge>}
              <DownOutlined />
            </div>
          </Button>
        </Dropdown>
      ),
    }
  ];

  return (
    <>
      <AntHeader className="header-nav">
        <div>
          <a href="/" style={{ display: "flex", flexDirection: "row" }}>
            <Image src={"/assets/coperos.png"} preview={false} width={22} />
            <h1 style={{ marginBottom: "0", marginTop: "0", marginLeft: "12px" }}>
              Coperos
            </h1>
          </a>
        </div>
        <Menu
          rootClassName="header-nav__menu"
          mode="horizontal"
          items={authed ? authedItems : unauthedItems}
        ></Menu>
      </AntHeader>
      <Modal
        width={1000}
        title="Reglamento"
        visible={isInstructionsVisible}
        footer={null}
        onCancel={() => setIsInstructionsVisible(false)}
      >
        <div className="instructions">
          <Instructions />
        </div>
      </Modal>
    </>
  );
}
