export const Config = {
    CURRENT_COMPETITION: process.env.REACT_APP_CURRENT_COMPETITION,
    API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
    AUTH_POOL_ID: process.env.REACT_APP_AUTH_POOL_ID,
    AUTH_CLIENT_ID: process.env.REACT_APP_AUTH_CLIENT_ID,
}
// export const Config = {
//     CURRENT_COMPETITION: "CA2024",
//     API_BASE_URL: "https://api-dev.coperos.com",
//     AUTH_POOL_ID: "us-east-1_7r6WdXe8O",
//     AUTH_CLIENT_ID: "15j82hsq9s1jfrlrsraoncnf6d"
// }