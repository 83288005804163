import { format } from "date-fns";
import { es } from "date-fns/locale";

const locales = { es };

export const getFormattedDateFromString = (date: string) => {
  let formattedDate = format(new Date(date), "d MMMM HH:mm", {
    locale: locales["es"],
  }).split(" ");
  return (
    formattedDate[0] +
    " de " +
    formattedDate[1].charAt(0).toUpperCase() +
    formattedDate[1].slice(1) +
    " " +
    formattedDate[2]
  );
};
