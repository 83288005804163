import { Typography } from "antd";

function Instructions() {

  return (
    <div className="instructions">
      <Typography.Title level={3}>Primera fase</Typography.Title>
      <Typography.Paragraph>
      El participante podrá efectuar todos los pronósticos, de la fase de grupos.
      </Typography.Paragraph>

      <Typography.Title level={3}>Segunda fase</Typography.Title>
      <Typography.Paragraph>
      De acuerdo a la clasificación final de los grupos, una vez finalizada la primera fase, se armaran las llaves para definir los partidos de 8vos de Final, 4tos de Final, Semi-Final y Final. También se tendrá en cuenta el partido por el tercer puesto. El Participante podrá realizar sus pronósticos por Fase.
      </Typography.Paragraph>
      <Typography.Paragraph>
      El Participante deberá guardar sus pronósticos para confirmar su participación.
      </Typography.Paragraph>

      <Typography.Title level={3}>Puntaje</Typography.Title>
      <Typography.Paragraph>
      El puntaje obtenido dependerá de los pronósticos realizados exclusivamente por el Participante, de acuerdo al resultado de cada partido del Torneo. Todos los puntajes obtenidos son exclusivamente en base a los resultados de los partidos del Torneo. Los puntajes serán acumulativos y el jugador que más puntos acumule durante la competencia será el ganador de la misma.
      </Typography.Paragraph>

      <Typography.Title level={3}>Formas de acumular puntos</Typography.Title>
      <Typography.Paragraph>
        <ul>
          <li>Acertar Ganador o empate de partido: <strong>3 Puntos</strong></li>
          <li>Acertar Resultado exacto de partido: <strong>2 Puntos extra (5 en total)</strong></li>
        </ul>
      </Typography.Paragraph>

      <Typography.Title level={3}>Premio</Typography.Title>
      <Typography.Paragraph>
      Los premios estarán a cargo del organizador de cada torneo.
      </Typography.Paragraph>

      <Typography.Title level={3}>Aclaración</Typography.Title>
      <Typography.Paragraph>
      <ul>
        <li>Se podrá realizar un solo pronóstico por participante, el cual deberá completarlo <strong>hasta 15 minutos antes del comienzo de cada encuentro</strong>.</li>
        <li><strong>En la fase de las llaves se tomará en cuenta el resultado final de los 90 minutos</strong>, sin considerar lo que sucediera en el tiempo extra o en los penales.</li>
        <li>No se restarán puntos por partidos sin pronosticar o con pronósticos no coincidentes con el resultado final.</li>
        <li>Si el partido resultare suspendido por alguna razón, se tomará en cuenta el resultado hasta el momento de la eventualidad.</li>
      </ul>
      </Typography.Paragraph>

      <Typography.Title level={3}>Unión a Torneos</Typography.Title>
      <Typography.Paragraph>
      <ul>
        <li>Los usuarios, podrán unirse a los torneos públicos sin autorización.</li>
        <li>Para los torneos privados, deberán solicitar autorización al administrador.</li>
      </ul>
      </Typography.Paragraph>

      <Typography.Title level={3}>Desempate</Typography.Title>
      <Typography.Paragraph>
      El mismo será aplicado por el Organizador del grupo según sus métodos elegidos y será comunicado a los participantes del Torneo.
      </Typography.Paragraph>

      <Typography.Title level={3}>Reglamento</Typography.Title>
      <Typography.Paragraph>
      Con fin de promover el juego, el Reglamento podrá ser actualizado sin previo aviso.
      </Typography.Paragraph>
    </div>
  );
}

export default Instructions;