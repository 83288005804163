import { useQuery } from "@tanstack/react-query";
import {
    getUserPoints
} from "../api/api";

const key = "USERS";

export function useUserPoints(userId: string, options: object = {}) {
  return useQuery([key, "points", userId], () => getUserPoints(userId), {
    ...options,
  });
}
