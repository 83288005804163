import Match from "./Match";
import { List, Select, Skeleton, Space, Spin, Typography } from "antd";
import { groupBy, sortBy } from "lodash";
import { useMatches, useTeams, useUserPredictions } from "../hooks/matches";
import { useContext, useState } from "react";
import { es } from "date-fns/locale";
import { format } from "date-fns";
import { AuthContext } from "./Providers/Auth";
import { MatchPrediction } from "./MatchPrediction";

function Matches() {
  const { userSession, authed } = useContext(AuthContext);
  const { data: teams, isLoading: isLoadingTeams } = useTeams();
  const { data: matches, isLoading: isLoadingMatches } = useMatches({ enabled: teams?.length > 0 });
  const [groupingBy, setGroupingBy] = useState("fecha");
  const { data: predictions, isLoading: isLoadingPredictions } = useUserPredictions(userSession?.user?.username);

  const GROUP_OPTIONS = [
    { label: "Etapa", value: "etapa" },
    { label: "Fecha", value: "fecha" },
  ];

  const locales = { es };
  const getFormattedDateFromString = (date: string) => {
    let formattedDate = format(new Date(date), "d MMMM", {
      locale: locales["es"],
    }).split(" ");
    return (
      formattedDate[0] +
      " de " +
      formattedDate[1].charAt(0).toUpperCase() +
      formattedDate[1].slice(1)
    );
  };

  const handleSortChange = (value: string) => {
    setGroupingBy(value);
  };

  const grouping = (match) => {
    if (groupingBy === "etapa") {
      return match.competitionStage;
    }
    return new Date(match.date).toLocaleDateString();
  };

  const getGroupLabel = (matches) => {
    const match = matches[0];
    if (groupingBy === "etapa") {
      return match.competitionStage;
    }
    return getFormattedDateFromString(new Date(match.date).toISOString());
  };

  const sortByDateAndState = (matchA, matchB) => {
    if (matchA.status === "FINISHED") {
      return 1;
    }
    if (matchB.status === "FINISHED") {
      return -1;
    }    
    const dateA = new Date(matchA.date);
    const dateB = new Date(matchB.date);
    if (dateA > dateB) {
      return 1;
    }
    if (dateA < dateB) {
      return -1;
    }
    return 0;
  }

  return isLoadingMatches || isLoadingTeams || (authed && isLoadingPredictions) ? (<Skeleton />) : (
    <>
      <Space
        className="matches-actions-bar"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginBottom: "1rem",
        }}
      >
        <Typography.Text strong>Agrupar por:</Typography.Text>
        <Select
          defaultValue={groupingBy}
          style={{ width: 120 }}
          onChange={handleSortChange}
          options={GROUP_OPTIONS}
          size="middle"
        />
      </Space>
      {sortBy(Object.entries<any[]>(groupBy(matches, grouping)), [0]).map(
        ([competitionStage, allMatches]) => (
          <div className="matches-stage" key={competitionStage}>
            <h2>{getGroupLabel(allMatches)}</h2>
            <List
              grid={{ column: 2, sm: 1, xs: 1, md: 1 }}
              dataSource={allMatches.sort(sortByDateAndState)}
              renderItem={(match) => (
                <List.Item style={{ margin: "8px" }}>
                  {authed ? (
                    <MatchPrediction
                      userId={userSession?.user?.username}
                      key={match.id}
                      match={match}
                      prediction={predictions?.find(
                        (prediction) => prediction.matchId === match.id
                      )}
                      teams={teams}
                      showGroup={groupingBy !== "etapa"}
                    />
                  ) : (
                    <Match
                      key={match.id}
                      {...match}
                      showGroup={groupingBy !== "etapa"}
                      teams={teams}
                    />
                  )}
                </List.Item>
              )}
            ></List>
          </div>
        )
      )}
    </>
  );
}

export default Matches;
