import {
  Space,
  Table,
  Typography,
  Button,
  Modal,
  Card,
  Form,
  Checkbox,
  Input,
  Row,
  Col,
  Image
} from "antd";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useDeinscriptionMutation,
  useInscriptionMutation,
  useTournament,
  useUpdateInscriptionStatusMutation,
  useUpdateTournament,
} from "../hooks/tournaments";
import type { ColumnsType } from "antd/es/table";
import { AuthContext } from "./Providers/Auth";
import { TournamentUser } from "@coperos/dtos/tournamentUser";
import { TournamentInscriptionStatus } from "@coperos/dtos/enums/tournamentInscriptionStatus";
import { ExclamationCircleOutlined, LockTwoTone } from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

function Tournament() {
  const { tournamentId } = useParams();
  const { userSession } = useContext(AuthContext);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const currentUserId = userSession?.user?.username;
  const { data: tournament, isLoading, error } = useTournament(tournamentId);
  const { mutate: mutateInscription, isLoading: isLoadingInscription } =
    useInscriptionMutation();
  const { mutate: mutateDeinscription, isLoading: isLoadingDeinscription } =
    useDeinscriptionMutation();
  const {
    mutate: mutateInscriptionStatus,
    isLoading: isLoadingInscriptionStatus,
  } = useUpdateInscriptionStatusMutation();
  const [input, setInput] = useState({
    name: "",
    description: "",
    isPrivate: false,
  });
  const { mutate, isLoading: isLoadingUpdateTournament } =
    useUpdateTournament();

  const showTournamentRequestModal = () => {
    setIsOpenModal(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    setInput({
      ...input,
      [e.target.name]: e.target.checked,
    });
  };

  const showEditModal = () => {
    setInput({
      name: tournament?.name,
      description: tournament?.description,
      isPrivate: tournament?.isPrivate,
    });
    setIsOpenEdit(true);
  };
  const handleOk = () => {
    mutate(
      { tournament: input, tournamentId },
      { onSuccess: () => setIsOpenEdit(false) }
    );
  };

  const handleCancel = () => {
    setIsOpenModal(false);
    setIsOpenEdit(false);
  };

  const showRejectConfirm = (userId) => {
    const isPending =
      tournament?.users.find((u) => u.id === userId).status ===
      TournamentInscriptionStatus.PENDING;
    Modal.confirm({
      cancelText: "Cancelar",
      okText: "Eliminar",
      title: isPending ? "Rechazar solicitud" : "Eliminar usuario",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: isPending
        ? "Seguro que queres rechazar esta solicitud?"
        : "Seguro que queres eliminar a este usuario?",
      onOk: () => mutateDeinscription({ tournamentId, userId }),
    });
  };

  const showDeinscriptionConfirm = () => {
    Modal.confirm({
      cancelText: "Cancelar",
      okText: "Desinscribirme",
      title: "Seguro queres desinscribirte del torneo?",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content:
        tournament?.isPrivate &&
        currentUserId !== tournament?.userId &&
        "La próxima vez que te inscribas el administrador deberá aceptar tu solicitud",
      onOk: () => mutateDeinscription({ tournamentId, userId: currentUserId }),
    });
  };

  const adminColumns: ColumnsType<TournamentUser> = [
    {
      title: "Usuario",
      dataIndex: "username",
      key: "username",
      width: "200px",
    },
    {
      title: "Acciones",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() =>
              mutateInscriptionStatus({ tournamentId, userId: record.id })
            }
          >
            Aceptar
          </Button>
          <Button
            danger
            type="primary"
            onClick={() => showRejectConfirm(record.id)}
          >
            Rechazar
          </Button>
        </Space>
      ),
    },
  ];

  const calculatePosition = (
    users: TournamentUser[],
    current: TournamentUser
  ) => {
    if (!users.length) {
      users.push({ ...current, position: 1 });
    } else {
      const lastUser = users[users.length - 1];
      if (lastUser.points === current.points) {
        users.push({ ...current, position: lastUser.position });
      } else {
        users.push({ ...current, position: lastUser.position + 1 });
      }
    }
    return users;
  };

  const columns: ColumnsType<TournamentUser> = [
    {
      title: "Posición",
      render: (_, record) => (
        <Typography.Text strong={record.id == currentUserId}>{record.position}</Typography.Text>
      ),
      key: "position",
      width: "100px",
    },
    {
      title: "Usuario",
      render: (_, record) => (
        <Typography.Text strong={record.id == currentUserId}>{record.username}</Typography.Text>
      ),
      key: "username",
    },
    {
      title: "Puntos",
      render: (_, record) => (
        <Typography.Text strong={record.id == currentUserId}>{record.points}</Typography.Text>
      ),
      key: "points",
      width: "100px",
    },
  ];

  if (currentUserId === tournament?.userId) {
    columns.push({
      title: "",
      key: "actions",
      width: "100px",
      render: (_, record) => {
        return (
          <Button
            danger
            type="link"
            onClick={() => showRejectConfirm(record.id)}
          >
            Remover usuario
          </Button>
        );
      },
    });
  }

  if (error) {
    return <p>Error: {error.toString()}</p>;
  }

  const inscription = tournament?.users.find(
    (user) => user.id === currentUserId
  );

  const pendingUsers = tournament?.users.filter(
    (u) => u.status === TournamentInscriptionStatus.PENDING
  );

  return (
    <>
      {tournament?.isSponsored && (
        <div style={{ backgroundImage: `url(${tournament?.branding?.banner})` }}>

        </div>
      )}
      <Row>
        <Col span={24}>
          <Card
            title={
              tournament?.isSponsored && tournament?.branding?.logo ? (
                <Image
                  height={'5rem'}
                  preview={false}
                  src={tournament?.branding?.logo}
                />
              ) : (
                <Typography.Title level={2}>
                  {tournament?.isPrivate && (
                    <>
                      <LockTwoTone style={{ fontSize: "22px" }} />{" "}
                    </>
                  )}
                  {tournament?.name}
                </Typography.Title>
              )
            }
            loading={isLoading}
            extra={
              currentUserId ? (
                <Space>
                  {pendingUsers?.length > 0 && currentUserId === tournament?.userId && (
                    <Button
                      type="link"
                      style={{
                        borderBottom: "2px solid",
                        padding: "4px 0px",
                        margin: "0px 15px",
                        boxSizing: "border-box",
                      }}
                      onClick={showTournamentRequestModal}
                    >
                      {pendingUsers?.length} Solicitudes pendientes
                    </Button>
                  )}
                  {currentUserId === tournament?.userId && (
                    <Button onClick={showEditModal}>Editar</Button>
                  )}
                  {inscription ? (
                    <Button
                      loading={isLoading || isLoadingDeinscription}
                      type="primary"
                      danger
                      onClick={
                        inscription.status === TournamentInscriptionStatus.ACCEPTED
                          ? showDeinscriptionConfirm
                          : () =>
                            mutateDeinscription({
                              tournamentId,
                              userId: currentUserId,
                            })
                      }
                    >
                      {inscription.status === TournamentInscriptionStatus.ACCEPTED
                        ? "Desinscribirme"
                        : "Cancelar solicitud"}
                    </Button>
                  ) : (
                    <Button
                      loading={isLoading || isLoadingInscription}
                      type="primary"
                      onClick={() => mutateInscription(tournamentId)}
                    >
                      {tournament?.isPrivate && tournament?.userId !== currentUserId
                        ? `Enviar solicitud de inscripción`
                        : "Inscribirme"}
                    </Button>
                  )}
                </Space>
              ) : (
                <></>
              )
            }
          >
            {tournament?.description && (
              <Typography.Paragraph>
                {tournament.description}
              </Typography.Paragraph>
            )}
            {tournament?.users && (
              <Row>
                <Col xs={24} sm={24} md={24} xl={16}>
                  <Table
                    bordered
                    dataSource={tournament?.users
                      .filter((u) => u.status === TournamentInscriptionStatus.ACCEPTED)
                      .sort((a, b) => b.points - a.points)
                      .reduce(calculatePosition, [])}
                    columns={columns}
                    rowKey="id"
                    size="small"
                    pagination={{
                      pageSize: 50,
                      hideOnSinglePage: true,
                      position: ["bottomCenter"],
                    }}
                  />
                </Col>
              </Row>
            )}
            <Modal
              title="Solicitudes de inscripción"
              visible={isOpenModal}
              footer={null}
              onCancel={handleCancel}
            >
              {tournament?.users && (
                <Table
                  bordered
                  dataSource={tournament?.users.filter(
                    (u) => u.status === TournamentInscriptionStatus.PENDING
                  )}
                  columns={adminColumns}
                  rowKey="id"
                  size="small"
                  pagination={{
                    pageSize: 50,
                    hideOnSinglePage: true,
                    position: ["bottomCenter"],
                  }}
                />
              )}
            </Modal>
            <Modal
              title="Editar torneo"
              visible={isOpenEdit}
              onOk={handleOk}
              confirmLoading={isLoadingUpdateTournament}
              onCancel={handleCancel}
              okText="Editar"
              cancelText="Cancelar"
            >
              <Form layout="vertical">
                <Form.Item rules={[{ required: true }]} label="Nombre">
                  <Input
                    name="name"
                    onChange={handleInputChange}
                    value={input.name}
                  />
                </Form.Item>
                <Form.Item label="Descripción">
                  <Input.TextArea
                    name="description"
                    onChange={handleTextAreaChange}
                    value={input.description}
                  />
                </Form.Item>
                <Form.Item name="disabled" valuePropName="checked">
                  <Checkbox
                    checked={input.isPrivate}
                    name="isPrivate"
                    onChange={handleCheckboxChange}
                  >
                    Privado
                  </Checkbox>
                  <Typography.Text type="secondary">
                    (Los usuarios deben ser aceptados por el administrador del torneo
                    para poder participar)
                  </Typography.Text>
                </Form.Item>
              </Form>
            </Modal>
          </Card>
        </Col>
      </Row>
    </>

  );
}

export default Tournament;
