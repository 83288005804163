import { useContext } from "react";
import { AuthContext } from "../../components/Providers/Auth";
import { ChangePassword } from "./ChangePassword";

export function Settings() {
  const { authed, logout } = useContext(AuthContext);

  return (
    <>
      {authed ? (
        <div>
          <ChangePassword></ChangePassword>
        </div>
      ) : (
        <h1>Please login</h1>
      )}
    </>
  );
}
