import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  List,
  Modal,
  Row,
  Skeleton,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  useCreatedTournaments,
  useCreateTournament,
  useInscribedTournaments,
  useTournaments,
} from "../hooks/tournaments";
import { AuthContext } from "./Providers/Auth";
import { LockTwoTone, SearchOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

function Tournaments() {
  const { authed } = useContext(AuthContext);
  useEffect(() => {
    if (!authed) {
      navigate("/login");
    }
  }, [authed]);

  const [name, setName] = useState("");
  const { userSession } = useContext(AuthContext);
  const currentUserId = userSession?.user?.username;
  const { data: createdTournaments, isLoading: isLoadingCreatedTournaments } = useCreatedTournaments(currentUserId);
  const { data: inscribedTournaments, isLoading: isLoadingInscribedTournaments } = useInscribedTournaments(currentUserId);

  const navigate = useNavigate();

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const { data, isLoading, error, refetch, isInitialLoading } = useTournaments(
    name.trim(),
    { enabled: false }
  );

  const [isOpenModal, setIsOpenModal] = useState(false);


  const defaultInput = {
    name: "",
    description: "",
    isPrivate: false,
  }
  const [input, setInput] = useState(defaultInput);
  const { mutate, isLoading: isLoadingCreateTournament } = useCreateTournament();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    setInput({
      ...input,
      [e.target.name]: e.target.checked,
    });
  };


  const showModal = () => {
    setInput(defaultInput);
    setIsOpenModal(true);
  };

  const handleOk = () => {
    mutate(input, {
      onSuccess: (data) => {
        navigate(`/tournament/${data.data.id}`);
        setIsOpenModal(false);
      }
    });
  };

  const handleCancel = () => {
    setIsOpenModal(false);
  };

  const config = {
    rules: [
      {
        type: "string" as any,
        required: true,
        message: "Este campo es obligatorio",
      },
    ],
  };

  return (
    <>
      <Modal
        title="Crear torneo"
        visible={isOpenModal}
        onOk={handleOk}
        confirmLoading={isLoadingCreateTournament}
        onCancel={handleCancel}
        okText="Crear"
        cancelText="Cancelar"
      >
        <Form layout="vertical">
          <Form.Item name="name" label="Nombre" {...config}>
            <Input name="name" value={input.name} onChange={handleInputChange} />
          </Form.Item>
          <Form.Item label="Descripción">
            <Input.TextArea
              name="description"
              value={input.description}
              onChange={handleTextAreaChange}
            />
          </Form.Item>
          <Form.Item name="disabled" valuePropName="checked">
            <Checkbox name="isPrivate" onChange={handleCheckboxChange} checked={input.isPrivate}>
              Privado
            </Checkbox>
            <Typography.Text type="secondary">
              (Los usuarios deben ser aceptados por el administrador del torneo
              para poder participar)
            </Typography.Text>
          </Form.Item>
        </Form>
      </Modal>
      <Row>
        <Col xl={{span: 18, push: 6}} md={{span: 16, push: 8}} sm={{span: 15, push: 9}} xs={{span: 24}}>
          <Content style={{ margin: "1rem" }}>
            <Input
              size="large"
              prefix={<SearchOutlined />}
              placeholder="Buscar torneo"
              onChange={handleSearchInputChange}
              onPressEnter={() => refetch()} />
            <List>
              {isLoading && isInitialLoading && (
                <List.Item>
                  <Card
                    bodyStyle={{
                      width: 1000,
                      display: "flex",
                    }}
                  >
                    <Skeleton
                      loading={isLoading}
                      active
                      title={false}
                      paragraph={{ rows: 1 }}
                    ></Skeleton>
                  </Card>
                </List.Item>
              )}
              {error && <p>Error: {error.toString()}</p>}
              {data &&
                data.length > 0 &&
                data.map((tournament) => (
                  <List.Item key={tournament.id}>
                    <Link
                      to={authed ? `/tournament/${tournament.id}` : "/login"}
                      style={{ display: "block", width: "100%" }}
                    >
                      <Card>
                        <Typography.Title level={4}>
                          {tournament.isPrivate && (
                            <Tooltip
                              title={`El creador del torneo debe aceptar la solicitud para poder participar.`}
                            >
                              <LockTwoTone />
                            </Tooltip>
                          )}{" "}
                          {tournament.name}
                        </Typography.Title>
                        <Typography>
                          Creado por: {tournament.owner?.username}
                        </Typography>
                      </Card>
                    </Link>
                  </List.Item>
                ))}
            </List>
          </Content>
        </Col>
        <Col xl={{span: 6, pull: 18}} md={{span: 8, pull: 16}} sm={{span: 9, pull: 15}} xs={{span: 24}}>
          <Space direction="vertical" size="large" style={{ display: "flex", marginTop: '1rem' }}>
            <Card
              title="Torneos inscriptos"
              bodyStyle={{ padding: 0 }}
            >
              <Skeleton style={{ padding: '1rem' }} loading={isLoadingInscribedTournaments} active title={false}></Skeleton>
              {(!isLoadingInscribedTournaments && inscribedTournaments?.filter(t => t.userId !== currentUserId && t.inscriptionStatus == 'ACCEPTED').length > 0) ? (
                <List
                  size="small"
                  dataSource={inscribedTournaments.filter(t => t.userId !== currentUserId && t.inscriptionStatus == 'ACCEPTED').sort((a, b) => a.name.localeCompare(b.name))}
                  renderItem={(item: any) => (
                    <List.Item className={'card-list-item__linked'} style={{ cursor: 'pointer' }} onClick={() => navigate(`/tournament/${item.id}`)}>
                      {item.isPrivate && <LockTwoTone />} {item.name}
                    </List.Item>
                  )} />) : (
                !isLoadingInscribedTournaments && (
                  <div style={{ padding: '1rem' }}>
                    <Typography.Text type="secondary">
                      No estás inscripto en ningún torneo.
                    </Typography.Text>
                  </div>
                )
              )
              }
            </Card>
            {!isLoadingInscribedTournaments && inscribedTournaments?.length > 0 && inscribedTournaments?.find(i => i.inscriptionStatus == 'PENDING') && (
              <Card
                title="Solicitudes enviadas"
                bodyStyle={{ padding: 0 }}
              >
                <Skeleton style={{ padding: '1rem' }} loading={isLoadingInscribedTournaments} active title={false}></Skeleton>
                {!isLoadingInscribedTournaments && inscribedTournaments?.length > 0 && <List
                  size="small"
                  dataSource={inscribedTournaments.filter(t => t.userId !== currentUserId && t.inscriptionStatus == 'PENDING').sort((a, b) => a.name.localeCompare(b.name))}
                  renderItem={(item: any) => (
                    <List.Item className={'card-list-item__linked'} style={{ cursor: 'pointer' }} onClick={() => navigate(`/tournament/${item.id}`)}>
                      {item.isPrivate && <LockTwoTone />} {item.name}
                    </List.Item>
                  )} />
                }
              </Card>
            )}
            <Card
              title="Torneos creados"
              bodyStyle={{ padding: 0 }}
              actions={[
                <div style={{ padding: '0 .6rem', display: 'flex', justifyContent: 'flex-end' }}>
                  <Button onClick={showModal} block={createdTournaments?.length == 0} type={createdTournaments?.length == 0 ? 'primary' : 'default'}>Crear torneo</Button>
                </div>
              ]}
            >
              <Skeleton style={{ padding: '1rem' }} loading={isLoadingCreatedTournaments} active title={false}></Skeleton>
              {!isLoadingCreatedTournaments && createdTournaments?.length > 0 && <List
                size="small"
                dataSource={createdTournaments.sort((a, b) => a.name.localeCompare(b.name))}
                renderItem={(item: any) => (
                  <List.Item className={'card-list-item__linked'} style={{ cursor: 'pointer' }} onClick={() => navigate(`/tournament/${item.id}`)}>
                    {item.isPrivate && <LockTwoTone />} {item.name}
                  </List.Item>
                )} />
              }
            </Card>
          </Space>
        </Col>
      </Row>
    </>


  );
}

export default Tournaments;
