import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";

interface Props {
  homeTeamGoals: number;
  setHomeTeamGoals: Function;
  awayTeamGoals: number;
  setAwayTeamGoals: Function;
}

function Counter({
  homeTeamGoals,
  setHomeTeamGoals,
  awayTeamGoals,
  setAwayTeamGoals,
}: Props) {
  const increase = (previousValue: number) => {
    if (previousValue === null) {
      return 0;
    }
    return ++previousValue;
  };

  const decrease = (previousValue: number) => {
    if (previousValue === null) {
      return 0;
    }
    return previousValue <= 0 ? 0 : --previousValue;
  };

  return (
    <div className="counter">
      {homeTeamGoals === null && awayTeamGoals === null ? (
        <div>
          <Button
            size="small"
            type="primary"
            onClick={() => {
              setHomeTeamGoals(0);
              setAwayTeamGoals(0);
            }}
          >
            Pronosticar
          </Button>
        </div>
      ) : (
        <>
          <div className="counter__team">
            <UpOutlined
              onClick={(e) => setHomeTeamGoals(increase(homeTeamGoals))}
            />
            <Typography.Text strong>
              {homeTeamGoals !== null ? homeTeamGoals : "-"}
            </Typography.Text>
            <DownOutlined
              onClick={() => setHomeTeamGoals(decrease(homeTeamGoals))}
            />
          </div>
          <Typography.Text strong>-</Typography.Text>
          <div className="counter__team">
            <UpOutlined
              onClick={() => setAwayTeamGoals(increase(awayTeamGoals))}
            />
            <Typography.Text strong>
              {awayTeamGoals !== null ? awayTeamGoals : "-"}
            </Typography.Text>
            <DownOutlined
              onClick={() => setAwayTeamGoals(decrease(awayTeamGoals))}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Counter;
