import { Match, Prediction, Team } from "@coperos/dtos";
import { Badge, Card, Image, Space, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import {
  getFormattedDateFromString,
  statusConverter,
} from "../commons/functions";
import useDebounce from "../commons/functions/useDebounce";
import { useSaveUserPrediction } from "../hooks/matches";
import Counter from "./Counter";

export function MatchPrediction({
  match,
  teams,
  userId,
  showGroup,
  prediction,
}: {
  match: Match;
  teams: Team[];
  userId: string;
  showGroup: boolean;
  prediction?: Prediction;
}) {
  const homeTeam = useMemo(
    () => teams.find((team) => team.code === match.homeTeam),
    [teams, match]
  );
  const awayTeam = useMemo(
    () => teams.find((team) => team.code === match.awayTeam),
    [teams, match]
  );

  const isAboutToStart = (match) => {
    const res = new Date(match.date).getTime() - new Date().getTime();
    return (res <= 15 * 60 * 1000);
  } 

  const isPast = (date) => {
    const res = new Date(date).getTime() - new Date().getTime();
    return res <= 0;
  }

  const canBePredicted = (match) => {
    return match.status === "SCHEDULED" && !isAboutToStart(match) && !isPast(match.date);
  }

  const { mutate } = useSaveUserPrediction();

  const [matchState, setMatchState] = useState({
    homeTeamGoals:
      prediction?.homeTeamGoals !== null &&
        prediction?.homeTeamGoals !== undefined
        ? prediction?.homeTeamGoals
        : null,
    awayTeamGoals:
      prediction?.awayTeamGoals !== null &&
        prediction?.awayTeamGoals !== undefined
        ? prediction?.awayTeamGoals
        : null,
  });

  const debouncedMatchState = useDebounce(matchState, 1000);

  useEffect(() => {
    if (
      debouncedMatchState.homeTeamGoals !== null &&
      debouncedMatchState.awayTeamGoals !== null &&
      (debouncedMatchState.homeTeamGoals !== prediction?.homeTeamGoals ||
        debouncedMatchState.awayTeamGoals !== prediction?.awayTeamGoals)
    ) {
      mutate({
        userId: userId,
        prediction: {
          matchId: match.id,
          homeGoals: debouncedMatchState.homeTeamGoals,
          awayGoals: debouncedMatchState.awayTeamGoals,
        },
      });
    }
  }, [debouncedMatchState]);

  const getColorForPoints = (points) => {
    if (points == 0) {
      return '#656565';
    } else if (points == 3) {
      return '#f08709';
    } 
    return '#538802';
  }

  return (
    <>
      <Card className="match-card prediction" size="default">
        <div className="match-card__team">
          <Image
            src={`/assets/round-flags/${homeTeam.code}.svg`}
            width={60}
            height={60}
            preview={false}
          />
          <Typography.Text strong>{homeTeam?.name}</Typography.Text>
        </div>
        <div className="match-card__details">
          {showGroup && (
            <Typography.Text strong style={{ fontSize: "14px", color: "#888" }}>
              {match.competitionStage}
            </Typography.Text>
          )}
          <Space direction="vertical" style={{display: 'flex', justifyContent: 'center'}}>
            <Typography.Text strong>
              {match.status === "SCHEDULED" ? (
                isAboutToStart(match) ? (
                  <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center'}}>
                  {getFormattedDateFromString(match.date as unknown as string)}
                  <Badge count={'Por comenzar'} style={{backgroundColor: 'purple'}}></Badge>
                </div>
                ) : (
                  <div style={{textAlign: 'center'}}>
                    {getFormattedDateFromString(match.date as unknown as string)}
                  </div>
                )
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography.Text style={{ fontSize: "20px" }}>
                    {match.homeTeamGoals} - {match.awayTeamGoals}
                  </Typography.Text>
                  <Badge
                    count={statusConverter(match.status)?.text}
                    style={{
                      backgroundColor: `${statusConverter(match.status)?.color}`,
                    }}
                  />
                </div>
              )}
            </Typography.Text>
            {canBePredicted(match) ? (
              <Counter
                homeTeamGoals={matchState.homeTeamGoals}
                awayTeamGoals={matchState.awayTeamGoals}
                setHomeTeamGoals={(value: any) =>
                  setMatchState((prevState) => ({
                    ...prevState,
                    homeTeamGoals: value,
                  }))
                }
                setAwayTeamGoals={(value: any) =>
                  setMatchState((prevState) => ({
                    ...prevState,
                    awayTeamGoals: value,
                  }))
                }
              />
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {prediction ? (
                  <>
                    <div className="match-card__your-prediction" style={{display: 'flex', alignItems: 'center'}}>
                      <Typography.Text style={{marginRight: '.2rem'}}>Tu pronóstico:</Typography.Text>
                      <div>
                        <Typography.Text strong> {prediction.homeTeamGoals} - {prediction.awayTeamGoals}</Typography.Text>
                        {prediction.points !== null && prediction.points != undefined && (
                          <Badge count={`+${prediction.points}`} style={{marginLeft: '.3rem',backgroundColor: getColorForPoints(prediction.points)}}>
                          </Badge>
                        )}
                      </div>
                    </div>
                  </>
                ) : 'Sin pronóstico'}
              </div>
            )}
          </Space>
        </div>
        <div className="match-card__team">
          <Image
            src={`/assets/round-flags/${awayTeam.code}.svg`}
            width={60}
            height={60}
            preview={false}
          />
          <Typography.Text strong>{awayTeam?.name}</Typography.Text>
        </div>
      </Card>
    </>
  );
}
