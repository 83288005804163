import { CognitoUser } from "amazon-cognito-identity-js";
import { Alert, Button, Col, Form, Input, Row, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../components/Providers/Auth";
import UserPool from "../../UserPool";

export function ForgotPassword() {
  const { authed } = useContext(AuthContext);
  useEffect(() => {
    if (authed) {
      navigate("/");
    }
  }, [authed]);
  
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [stage, setStage] = useState(1);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const getUser = (email: string) => {
    return new CognitoUser({
      Username: email.toLocaleLowerCase(),
      Pool: UserPool,
    });
  };

  const onEmailSubmit = async (values: { email: string }) => {
    try {
      setError(null);
      setEmail(values.email);
      getUser(values.email).forgotPassword({
        onSuccess: () => {
          setMessage("Código de confirmación enviado.");
        },
        onFailure: (err) => {
          setError(err.message);
        },
        inputVerificationCode: () => {
          setStage(2);
        },
      });
    } catch (err) {
      setError(err.message);
    }
  };

  const onCodeSubmit = async (values: { password: string; code: string }) => {
    try {
      setError(null);
      getUser(email).confirmPassword(values.code, values.password, {
        onSuccess: () => {
          navigate("/login");
        },
        onFailure: (err) => {
          setError(err.message);
        },
      });
    } catch (err) {
      setError(err.message);
    }
  };

  const SendCode = () => {
    return (
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onEmailSubmit}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Ingresá tu email" }]}
        >
          <Input />
        </Form.Item>
        {(message || error) && (
          <Form.Item wrapperCol={{ offset: 4 }}>
            {message && <Alert message={message} type="success" />}
            {error && <Alert message={error} type="error" />}
          </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enviar código
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const ConfirmPassword = () => {
    return (
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onCodeSubmit}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="Nueva contraseña"
          name="password"
          rules={[{ required: true, message: "Ingresá tu nueva contraseña." }]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="Confirmar nueva contraseña"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Confirmá tu nueva contraseña.",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("La contraseña y la confirmación deben coincidir.")
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Código de verificación"
          name="code"
          rules={[
            { required: true, message: "Ingresá el código de verificación que enviamos a tu email." },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Cambiar contraseña
          </Button>
        </Form.Item>

        {message && <Alert message={message} type="success" />}
        {error && <Alert message={error} type="error" />}
      </Form>
    );
  };

  return (
    <>
    <Row style={{ padding: '0 1rem' }}>
      <Col md={{ span: 8, offset: 8 }} sm={{ span: 16, offset: 4 }} xs={{ span: 24, offset: 0 }}>
      
        <Typography.Title level={2}>Olvidaste tu contraseña?</Typography.Title>
        {stage === 1 && <SendCode />}
        {stage === 2 && <ConfirmPassword />}
      
      </Col>
      </Row>
    </>
  );
}
