import { useContext, useEffect, useState } from "react";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import UserPool from "../../UserPool";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Col, Form, Input, Row, Typography } from "antd";
import { AuthContext } from "../../components/Providers/Auth";

export function Signup() {
  const { authed } = useContext(AuthContext);
  useEffect(() => {
    if (authed) {
      navigate("/");
    }
  }, [authed]);
  
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values: {
    email: string;
    username: string;
    password: string;
    remember: boolean;
  }) => {
    setIsLoading(true);
    try {
      setError(null);
      const attPreferredUsername = new CognitoUserAttribute({
        Name: "preferred_username",
        Value: values.username,
      });

      UserPool.signUp(
        values.email.toLocaleLowerCase(),
        values.password,
        [attPreferredUsername],
        [],
        (err, data) => {
          setIsLoading(false);
          if (err) {
            switch(err['code']){
              case 'UsernameExistsException':
                setError('El email ya está registrado.');
                break;
              case 'UserLambdaValidationException':
                setError('El alias está en uso.');
                break;
              default:
                setError(err.message);
            }
            
          } else {
            navigate("/verify-email?email=" + encodeURIComponent(values.email));
          }
        }
      );
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Row style={{ padding: '0 1rem' }}>
        <Col md={{ span: 8, offset: 8 }} sm={{ span: 16, offset: 4 }} xs={{ span: 24, offset: 0 }}>
          <Typography.Title level={2}>Registrate</Typography.Title>

          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onSubmit}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="Alias"
              tooltip="Tu alias es el nombre que se mostrará públicamente."
              name="username"
              rules={[{ required: true, message: "Ingresá tu alias." }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Ingresá tu email." }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Contraseña"
              name="password"
              rules={[{ required: true, message: "Ingresá tu contraseña." }]}
            >
              <Input.Password />
            </Form.Item>

            
            {error && <Form.Item><Alert message={error} type="error" /></Form.Item>}  

            <Form.Item>
              <Button loading={isLoading} type="primary" htmlType="submit">
                Registrarme
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
}
