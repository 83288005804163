import { CognitoUser } from "amazon-cognito-identity-js";
import { Alert, Button, Col, Form, Input, Row, Space, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../components/Providers/Auth";
import UserPool from "../../UserPool";

export function VerifyEmail() {
  const { authed } = useContext(AuthContext);
  useEffect(() => {
    if (authed) {
      navigate("/");
    }
  }, [authed]);
  
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [email, setEmail] = useState(searchParams.get("email") || "");

  const [isResendingCode, setIsResendingCode] = useState(false);
  const [isVerifyingCode, setIsVerifyingCode] = useState(false);

  const onSubmit = async (values: { email: string; code: string }) => {
    try {
      setError(null);
      const cognitoUser = new CognitoUser({
        Username: values.email.toLocaleLowerCase(),
        Pool: UserPool,
      });

      setIsVerifyingCode(true);
      cognitoUser.confirmRegistration(values.code, false, (err, result) => {
        setIsVerifyingCode(false);
        if (err) {
          console.log(err['code'])
          switch (err['code']) {
            case 'CodeMismatchException':
              setError('El código es incorrecto.');
              break;
            case 'ExpiredCodeException':
              setError('El código ha expirado.');
              break;
            case 'LimitExceededException':
              setError('Demasiados intentos.');
              break;
            case 'NotAuthorizedException':
              setError('Usuario ya verificado.');
              break;
            default:
              console.log(err['code'])
              setError(err.message);
          }
        } else {
          navigate("/login");
        }
      });
    } catch (err) {
      setError(err.message);
      setIsVerifyingCode(false);
    }
  };

  const resendVerificationEmail = async () => {
    setIsResendingCode(true);
    try {
      setError(null);
      const cognitoUser = new CognitoUser({
        Username: email.toLocaleLowerCase(),
        Pool: UserPool,
      });
      cognitoUser.resendConfirmationCode((err, result) => {
        setIsResendingCode(false);
        if (err) {
          console.log(err['code'])
          switch (err['code']) {
            case 'InvalidParameterException':
              setError('El email no es válido.');
              break;
            case 'UserNotFoundException':
              setError('El usuario no existe.');
              break;
            case 'LimitExceededException':
              setError('Demasiados intentos. Intente más tarde.');
              break;
            default:
              setError(err.message);
          }
        } else {
          setError(null);
        }
      })
    } catch (err) {
      setError(err.message);
      setIsResendingCode(false);
    }
  }

  return (
    <>
      <Row style={{ padding: '0 1rem' }}>
        <Col md={{ span: 8, offset: 8 }} sm={{ span: 16, offset: 4 }} xs={{ span: 24, offset: 0 }}>
          <Typography.Title level={2}>Verificá tu Email</Typography.Title>
          <Typography.Paragraph>
            Enviamos un código de verificación a tu email.
          </Typography.Paragraph>

          <div style={{marginBottom: '1rem'}}>
            No recibiste el código? <Button size="small" loading={isResendingCode} type="link" onClick={resendVerificationEmail}>Reenviar código</Button>
          </div>

          <Form
            name="basic"
            initialValues={{
              remember: true,
              email: searchParams.get("email") || "",
            }}
            onFinish={onSubmit}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input onChange={(e) => setEmail(e.target.value)} />
            </Form.Item>

            <Form.Item
              label="Código de verificación"
              name="code"
              rules={[
                {
                  required: true,
                  message: "Ingresá el código de verificación que enviamos a tu email.",
                },
              ]}
            >
              <Input />
            </Form.Item>

            {error && <Form.Item><Alert message={error} type="error" /></Form.Item>}

            <Form.Item>
              <Button loading={isVerifyingCode} type="primary" htmlType="submit">
                Verificar
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
}
