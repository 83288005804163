import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { createContext, useState } from "react";
import UserPool from "../../UserPool";

const AuthContext = createContext(undefined);

const Auth = ({ children }) => {
  const [authed, setAuthed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userSession, setUserSession] = useState(null);

  const getSession = async () => {
    setLoading(true);
    return await new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            setAuthed(false);
            setLoading(false);
            reject();
          } else {
            const attributes = (await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err);
                } else {
                  const results = {};
                  for (let attribute of attributes) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }
                  resolve(results);
                }
              });
            })) as Record<string, unknown>;
            setAuthed(true);
            setLoading(false);
            setUserSession({
              user,
              ...session,
              ...attributes,
            });
            resolve({
              user,
              ...session,
              ...attributes,
            });
          }
        });
      } else {
        setAuthed(false);
        setLoading(false);
        reject();
      }
    });
  };

  const authenticate = async (username: string, password: string) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: username.toLocaleLowerCase(),
        Pool: UserPool,
      });

      const authDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          getSession();
          resolve(data);
        },
        onFailure: (err) => {
          setAuthed(false);
          reject(err);
        },
        newPasswordRequired: (data) => {
          setAuthed(false);
          resolve(data);
        },
      });
    });
  };

  const logout = () => {
    const user = UserPool.getCurrentUser();
    if (user) {
      setAuthed(false);
      setUserSession(null);
      user.signOut();
      window.location.replace("/");
    }
  };

  const value = {
    authenticate,
    getSession,
    logout,
    authed,
    loading,
    userSession,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { Auth, AuthContext };
