import { Button, Col, Form, Input, Row, Typography, message, Alert } from "antd";
import { useContext, useState } from "react";
import { AuthContext } from "../../components/Providers/Auth";

export function ChangePassword() {
  const [form] = Form.useForm();
  const [error, setError] = useState(null);

  const [isLoadingAction, setIsLoadingAction] = useState(false);

  const { userSession } = useContext(AuthContext);

  // On submit, register user
  const onSubmit = async (values: {
    password: string;
    newPassword: string;
  }) => {
    setIsLoadingAction(true);
    try {
      setError(null);
      userSession.user.changePassword(
        values.password,
        values.newPassword,
        (err, result) => {
          setIsLoadingAction(false);
          if (err) {
            switch(err['code']) {
              case 'NotAuthorizedException':
                setError('La contraseña actual es incorrecta.');
                break;
              case 'LimitExceededException':
                setError('Demasiados intentos. Vuelve a intentarlo más tarde.');
                break;
              default:
                console.log(err['code'])
                console.log(JSON.stringify(err))
                setError(err.message);
            }
          } else {
            message.success("Contraseña cambiada con éxito");
            form.resetFields();
          }
        }
      );
    } catch (err) {
      setError(err.message);
      setIsLoadingAction(false);
    }
  };

  return (
    <>
      <Row style={{ padding: '0 1rem' }}>
        <Col md={{ span: 8, offset: 8 }} sm={{ span: 16, offset: 4 }} xs={{ span: 24, offset: 0 }}>
        <Typography.Title level={2}>Cambiar contraseña</Typography.Title>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        autoComplete="off"
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="Contraseña Actual"
          name="password"
          rules={[{ required: true, message: "Ingresá tu password actual." }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Nueva Contraseña"
          name="newPassword"
          rules={[{ required: true, message: "Ingresá tu nueva password." }]}
        >
          <Input.Password />
        </Form.Item>

        {error && <Form.Item><Alert message={error} type="error" /></Form.Item>}

        <Form.Item>
          <Button loading={isLoadingAction} type="primary" htmlType="submit">
            Cambiar Contraseña
          </Button>
        </Form.Item>
      </Form>
      </Col>
      </Row>
    </>
  );
}
