import Matches from "../../components/Matches";

interface Props {
  user: any;
}

export function Home(props: Props) {
  return (
    <>
      <Matches />
    </>
  );
}
