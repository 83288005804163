import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../components/Providers/Auth";
import { Button, Form, Input, Alert, Typography, Row, Col } from "antd";
import { Link, useNavigate } from "react-router-dom";

export function Login() {
  const { authenticate, authed } = useContext(AuthContext);
  useEffect(() => {
    if (authed) {
      navigate("/");
    }
  }, [authed]);

  const [error, setError] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  // On submit, register user
  const onSubmit = async (values: {
    email: string;
    password: string;
    remember: boolean;
  }) => {
    setIsLoading(true);
    try {
      setError(null);
      await authenticate(values.email.toLocaleLowerCase(), values.password);
      navigate("/");
    } catch (err) {
      switch (err.code) {
        case "UserNotConfirmedException":
          navigate("/verify-email?email=" + encodeURIComponent(values.email));
          break;
        case "NotAuthorizedException":
          setError("Usuario o contraseña incorrectos.");
          break;
        case "UserNotFoundException":
          setError("El email no está registrado.");
          break;
        default:
          setError(err.message);
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <Row style={{ padding: '0 1rem' }}>
        <Col md={{ span: 8, offset: 8 }} sm={{ span: 16, offset: 4 }} xs={{ span: 24, offset: 0 }}>
          <Typography.Title level={2}>Ingresar</Typography.Title>

          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onSubmit}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Ingresá tu email." }]}
            >
              <Input type="email" />
            </Form.Item>

            <Form.Item
              label="Contraseña"
              name="password"
              rules={[{ required: true, message: "Ingresá tu password." }]}
            >
              <Input.Password />
            </Form.Item>

            
            {error && <Form.Item><Alert message={error} type="error" /></Form.Item>}

            <Form.Item>
              <Link to="/forgot-password">Olvidaste tu contraseña?</Link>
            </Form.Item>

            <Form.Item>
              <Button loading={isLoading} type="primary" htmlType="submit">
                Ingresar
              </Button>
            </Form.Item>
            </Form>
        </Col>
      </Row>
    </>
  );
}
